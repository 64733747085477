<template>
  <a-card :bordered="false" class="card-area">

    <common-table
      ref="table"
      path="web/app-interact"
      :columns="columns">

      <template slot="search">
        <a-button  ghost type="primary" @click="add">新增</a-button>
      </template>

      <template slot="logo" slot-scope="{record}">
        <a-avatar shape="square" :size="60" :src="record.logo">{{record.title}}</a-avatar>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-message  @click="viewComment(record)"></action-message>
        <action-edit  @click="edit(record)"></action-edit>
        <action-delete  @click="remove(record)"></action-delete>
      </template>

    </common-table>

    <interact-edit ref="editDrawer" @success="getList"></interact-edit>
  </a-card>
</template>
<script>
import InteractEdit from './InteractEdit'
export default {
  components: {
    InteractEdit
  },
  data () {
    return {

    }
  },

  computed: {
    columns () {
      return [
        {
          title: '',
          dataIndex: 'logo',
          scopedSlots: { customRender: 'logo' }
        },
        {
          title: '标题',
          dataIndex: 'title'
        },
        //        {
        //          title: '简介',
        //          dataIndex: 'des'
        //        },
        {
          title: '作者',
          dataIndex: 'author'
        },
        {
          title: '更新时间',
          dataIndex: 'updatedAt'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width:120,
          scopedSlots: { customRender: 'operation' }
        }
      ]
    }
  },
  methods: {
    add () {
      this.$refs.editDrawer.show()
    },
    edit (record) {
      this.$refs.editDrawer.show(record)
    },
    remove (record) {
      var that = this
      this.$confirm({
        title: '是否删除该记录?',
        content: '该操作不可撤销',
        centered: true,
        onOk () {
          that.$delete('web/app-interact/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
          })
        }
      })
    },
    viewComment (record){
      var newsId = record.id
      this.$router.push(`/app/news/${newsId}/comment`)
    },

    getList () {
      this.$refs.table.getData()
    }

  }
}
</script>
<style lang="less" scoped>

.news-logo {
  width: 60px;
  height: 60px;
}
</style>
